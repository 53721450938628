<template>
    <div class="h-100 d-flex flex-column">
        <div v-if="frames[currentFrame]">
            Frame ID: {{ frames[currentFrame].id }}
            Total annotated: {{ frames.length -1 }}
        </div>

        <div class="d-flex justify-space-between flex-column flex-sm-row">
            <div class="col-12 col-sm-9">
                <img v-if="frames[currentFrame]" class="img-fluid" :src="frames[currentFrame].url" alt="">
            </div>

            <div class="d-flex flex-column">
                <div class="d-flex me-4 justify-content-center mt-3">
                    <div class="flex h-100 flex-column justify-content-center">
                        <div class="d-grid ms-4">
                            <button class="btn btn-lg btn-block btn-success" :disabled="currentFrame == 0" @click="back">Back</button>
                        </div>
    
                        <div class="d-grid ms-4 mt-3">
                            <button class="btn btn-lg btn-block" :class="{ 'btn-outline-primary': !blueKick, 'btn-primary': blueKick }" @click="blueKick = !blueKick; $event.target.blur()" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">Blue Kick</button>
                        </div>
                    </div>    
    
                    <div class="flex h-100 flex-column justify-content-center">
                        <div class="d-grid ms-4">
                            <button class="btn btn-lg btn-block btn-success" :disabled="currentFrame == frames.length" @click="save">Save</button>
                        </div>
    
                        <div class="d-grid ms-4 mt-3">
                            <button class="btn btn-lg btn-block" :class="{ 'btn-outline-danger': !redKick, 'btn-danger': redKick }" @click="redKick = !redKick; $event.target.blur()">Red Kick</button>
                        </div>
                    </div>
                </div>

                <div class="me-4">
                    <div class="d-grid ms-4 mt-2 mx-5 ps-5">
                        <button class="btn btn-lg btn-block" :class="{ 'btn-outline-warning': !contact, 'btn-warning': contact }" @click="contact = !contact; $event.target.blur()">Contact</button>
                    </div>
                    <div class="d-grid ms-4 mt-4">
                        <button class="btn btn-lg btn-block btn-danger" @click="flagFrame">Flag Frame</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import api from "@/api";

export default {
    data() {
        return {
            blueKick: false,
            redKick: false,
            contact: false,
            currentFrame: 0,
            frames: [],
        }
    },
    components: {
        
    },
    async mounted() {
        this.$store.commit('UPDATE_SITE_CONFIG', { key: "isSidebarHidden", value: true })
        this.loadMoreFrames();
        console.log(`Loaded frames`)
        document.addEventListener('keydown', this.processHotkey);
    },
    destoryed() {

    },
    methods: {
        async processHotkey(e) {
            let keyCode = e.keyCode || e.which || e.code;
            console.log(keyCode)
            if (keyCode == 100) this.blueKick = !this.blueKick; // numpad 4
            if (keyCode == 101) this.contact = !this.contact; // numpad 5
            if (keyCode == 102) this.redKick = !this.redKick; // numpad 6
            if (keyCode == 109) this.flagFrame(); // numpad -

            if (keyCode == 65) this.back(); // a
            if (keyCode == 83) this.save(); // s
            if (keyCode == 68) this.next(); // d



            if (keyCode == 49) this.redKick = !this.redKick; // 1
            if (keyCode == 50) this.blueKick = !this.blueKick; // 2
            if (keyCode == 51) this.contact = !this.contact; // 3
            if (keyCode == 39) this.save(); // ->
            if (keyCode == 38) this.next(); // ^
            if (keyCode == 37) this.back(); // <-
            if (keyCode == 40) this.flagFrame(); // \/
        },
        async back() {
            if (this.currentFrame <= 0) return;
            this.currentFrame--;
        },
        async save() {
            
            const frame = this.frames[this.currentFrame];
            frame.labels = [];
            frame.labelVersion = '2';

            if (this.redKick)   frame.labels.push('RED_KICK');
            if (this.blueKick)  frame.labels.push('BLUE_KICK');
            if (this.contact)   frame.labels.push('CONTACT');

            await api.post(`/Frames`, frame);

            if (this.frames.length-1 == this.currentFrame) await this.loadMoreFrames();
            this.next();
        },
        async next() {
            if (this.frames.length-1 == this.currentFrame) return;
            this.currentFrame++;
        },
        async loadMoreFrames() {
             console.log(`asking for more frames (page ${this.framesPage})`);
            // if (!this.selectedViewpoint) return console.log("No selected viewpoint watf?")
            let { data: frames } = await api.get(`/Frames/not-annotated`);
            this.frames.push(...frames)
        },
        async flagFrame() {
            const frame = this.frames[this.currentFrame];
            frame.flagged = true;
            await api.post(`/Frames`, frame);
            if (this.frames.length-1 == this.currentFrame) await this.loadMoreFrames();
            this.next();
        }
    },
    computed: {
       framesLeft() {
           return frames.length - currentFrame
       }
    },
    watch: {
    }
}
</script>

<style scoped>
.btn:focus {
  outline: none;
  box-shadow: none;
}
</style>